import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async connect() {
    this.subscription = await window.cable.subscribeTo(this.channel, { 
      received(data) {
        const currentUserId = document.body.dataset.currentUserId
        console.debug("Online agents updated: ", `online-agent-${currentUserId}`)
        document.getElementById("online-agents-dropdown").innerHTML = data.online_agents
        document.getElementById(`online-agent-${currentUserId}`)?.remove()
      }
    })
  }

  disconnect() {
    if (this.subscription) this.subscription.unsubscribe()
  }

  get channel() {
    const channel = "ServiceCenterChannel"
    const id = 1
    return { channel, id }
  }
}
