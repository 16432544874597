import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submit(event) {
    this.element.closest("form").requestSubmit()
  }

  submitDebounce(event) {
    if (event.target.value.length > 0)
      document.getElementById("service-request-search-spinner")?.classList?.remove("hidden")

    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.element.closest("form").requestSubmit()
    }, 500)
  }
}
