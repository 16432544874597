import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const selectedCategory = document.querySelector('input[name="service_request[category_id]"]:checked')?.value
    this.showCategoryBasedFields(selectedCategory)

    document.addEventListener('turbo:morph', (e) => {
      const selectedCategory = document.querySelector('input[name="service_request[category_id]"]:checked')?.value
      this.showCategoryBasedFields(selectedCategory)
      window.scrollTo(0, 0)
    })
  }

  showCategoryBasedFields(selectedCategory) {
    if (selectedCategory !== undefined) {
      this.element.querySelector("#service_request_appointment_needed_container").classList.remove("hidden")  
      this.showFieldsBasedOnCategory(selectedCategory)
      this.showAppointmentFields()
      this.dimOtherCategoryIcons(selectedCategory)
    }
  }

  showAlwaysVisibleFields() {
    this.element.querySelector("#user_first_name")?.parentElement.classList.remove('hidden')
    this.element.querySelector("#user_gender")?.parentElement.classList.remove('hidden')
    this.element.querySelector("#user_state")?.parentElement.classList.remove("hidden")
    this.element.querySelector("#service_request_description").parentElement.parentElement.classList.remove("hidden")
    this.element.querySelector("#service_request_appointment_needed_container").classList.remove("hidden")
  }

  categoryChanged(event) {
    const selectedCategory = event.target.value

    this.element.querySelectorAll("[id*='user_']").forEach((e) => {
      e.parentElement.classList.add("hidden")
    })
    this.element.querySelectorAll("#description_infotext").forEach((el) => {
      el.classList.add("hidden")
    })
    this.element.querySelectorAll("#attachments_infotext").forEach((el) => {
      el.classList.add("hidden")
    })
    this.element.querySelector(`#place_name_infotext[data-category='health']`).classList.add("hidden")

    this.element.querySelector("#service_request_editor_name").parentElement.classList.add("hidden")
    this.element.querySelector("#service_request_reference_number").parentElement.classList.add("hidden")
    this.element.querySelector("#service_request_editor_name").parentElement.classList.add("hidden")

    this.showAlwaysVisibleFields()
    this.showFieldsBasedOnCategory(selectedCategory)
    this.showAppointmentFields()
    this.dimOtherCategoryIcons(selectedCategory)
  }

  dimOtherCategoryIcons(category) {
    const selectedStateClass = "opacity-70"
    document.querySelectorAll('label[for*="service_request_category_"]').forEach((e) => {
      e.classList.add(selectedStateClass)
      e.parentElement.querySelector("svg").classList.add(selectedStateClass)
    })
    document.querySelector(`label[for*="service_request_category_${category}"]`).classList.remove(selectedStateClass)
    document.querySelector(`label[for*="service_request_category_${category}"]`).parentElement.querySelector("svg").classList.remove(selectedStateClass)
  }

  showFieldsBasedOnCategory(category) {
    this.element.querySelector("#service_request_attachments").classList.remove("hidden")
    
    if (category === "") {
      this.element.querySelector("#service_request_phone_number").parentElement.classList.add("hidden")
      this.element.querySelector("#service_request_place_name").parentElement.classList.add("hidden")
      this.element.querySelector("#service_request_appointment_needed_container").classList.add("hidden")
    } else {
      const infotext = this.element.querySelector(`#description_infotext[data-category='${category}']`)
      if (infotext !== null) { infotext.classList.remove("hidden") }

      const attachmentInfotext = this.element.querySelector("#attachments_infotext")
      if (attachmentInfotext !== null) { attachmentInfotext.classList.remove("hidden") }

      this.element.querySelector("#service_request_description").parentNode.closest(".hidden")?.classList?.remove("hidden")
      this.element.querySelector("#service_request_phone_number").parentElement.classList.remove('hidden')
      this.element.querySelector("#service_request_place_name").parentElement.classList.remove('hidden')
      this.element.querySelector("#user_reschedule_contact_info")?.parentElement.classList.remove("hidden")
    }

    if (category === "car") {
      this.element.querySelector("#user_license_plate").parentElement.classList.remove("hidden")
    }

    if (category === "education") {
      this.element.querySelector("#user_social_security_number").parentElement.classList.remove("hidden")
    }

    if (category === "health") {
      this.element.querySelector("#user_social_security_number").parentElement.classList.remove("hidden")
      const infotext = this.element.querySelector(`#place_name_infotext[data-category='health']`)
      if (infotext !== null) { infotext.classList.remove("hidden") }
    }

    if (category === "official_channel") {
      this.element.querySelector("#user_address").parentElement.classList.remove("hidden")
      this.element.querySelector("#user_social_security_number").parentElement.classList.remove("hidden")
      this.element.querySelector("#service_request_editor_name").parentElement.classList.remove("hidden")
      this.element.querySelector("#service_request_reference_number").parentElement.classList.remove("hidden")
    }

    if (category === "work") {
      this.element.querySelector("#service_request_editor_name").parentElement.classList.remove("hidden")
    }

    if (category === "finance") {
      this.element.querySelector("#user_social_security_number").parentElement.classList.remove("hidden")
    }

    if (category === "living") {
      this.element.querySelector("#service_request_editor_name").parentElement.classList.remove("hidden")
      this.element.querySelector("#user_address").parentElement.classList.remove("hidden")
      this.element.querySelector("#service_request_reference_number").parentElement.classList.remove("hidden")
    }
  }

  showAppointmentFields() {
    const appointmentNeeded = document.querySelector('input[name="service_request[appointment_needed]"]:checked')?.value
    if (appointmentNeeded == "true") {
      this.appointmentEnable()
    } else {
      this.appointmentDisable()
    }
  }

  appointmentEnable() {
    this.element.querySelector("#service_request_desired_date_time").parentElement.classList.remove("hidden")
  }

  appointmentDisable() {
    this.element.querySelector("#service_request_desired_date_time").parentElement.classList.add("hidden")
  }
}
