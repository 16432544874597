import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "chat", "submit"]

  open() {
    this.iconTarget.classList.toggle("rotate-0")
    this.iconTarget.classList.toggle("rotate-180")
    this.chatTarget.classList.toggle("hidden")
  }
}
