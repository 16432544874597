import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.setNavIndicator("nav .agent-call-nav-item.active", '#agent-call-marker', true, false)
    const self = this
  }

  hideAll() {
    document.querySelector("#service-request-details-container").classList.add("hidden")
    document.querySelector("[id*='all_service_requests_service_request']").classList.add("hidden")
    document.querySelector("[id*='client_profile_service_request']").classList.add("hidden")
  }

  showDetails(event) {
    this.hideAll()
    document.querySelector("#service-request-details-container").classList.remove("hidden")
    this.toggleActiveClass(event)
    window.setNavIndicator("nav .agent-call-nav-item.active", '#agent-call-marker', true, false)
  }

  showOtherRequests(event) {
    this.hideAll()
    document.querySelector("[id*='all_service_requests_service_request']").classList.remove("hidden")
    this.toggleActiveClass(event)
    window.setNavIndicator("nav .agent-call-nav-item.active", '#agent-call-marker', true, false)
  }

  showClientProfile(event) {
    this.hideAll()
    document.querySelector("[id*='client_profile_service_request']").classList.remove("hidden")
    this.toggleActiveClass(event)
    window.setNavIndicator("nav .agent-call-nav-item.active", '#agent-call-marker', true, false)
  }

  toggleActiveClass(event) {
    document.querySelectorAll('nav .agent-call-nav-item').forEach(item => {
      item.classList.remove("active")
    })
    event.target.closest("button").classList.add("active")
  }
}
