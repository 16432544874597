import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    sender: String,
    other: String,
    senderId: String
  }

  connect() {
    const currentUserId = document.body.dataset.currentUserId

    if (currentUserId === this.senderIdValue) {
      this.element.classList.add(...this.senderValue.split(' '))
      if (document.body.dataset.currentRole === "agent") {
        if (this.element.querySelector("#user-name")) {
          this.element.querySelector("#user-name").innerText = document.body.dataset.currentUserName
        }
      }
    } else {
      this.element.classList.add(...this.otherValue.split(' '))
    }
  }
}
