console.debug("APP: process.env.NODE_ENV:", process.env.NODE_ENV, "| import.meta.env.MODE: ", import.meta.env.MODE)

import * as Sentry from "@sentry/browser";

if (import.meta.env.MODE !== "development") {
  Sentry.init({
    dsn: "https://14141c52dd5c3f1e57bb20128a625f6e@o4506908470476800.ingest.us.sentry.io/4506908473229312",
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  if (document.body.dataset.currentUserEmail) {
    console.debug("Initialize Sentry, user: ", document.body.dataset.currentUserEmail)
    Sentry.setUser({ id: document.body.dataset.currentUserId, email: document.body.dataset.currentUserEmail });
  } else {
    console.debug("Initialize Sentry, no user", document.body.dataset.currentUserEmail)
    Sentry.setUser(null); 
  }
}

import { Application } from "@hotwired/stimulus"
import * as ActiveStorage from "@rails/activestorage"

const application = Application.start()
ActiveStorage.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

import { cable } from "@hotwired/turbo-rails"
window.cable = cable
