import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nav"]

  close() {
    this.navTarget.classList.add("hidden")
  }

  open() {
    this.navTarget.classList.remove("hidden")
  }
}
