import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    dismissAfter: { type: Number, default: 5000 },
    showDelay: { type: Number, default: 200 },
    removeDelay: { type: Number, default: 1100 }
  }
  static classes = ["show", "hide"]

  initialize() {
    this.hide()
  }

  connect() {
    if (this.element.parentElement.id !== "flash_messages") {
      document.querySelector("#flash_messages").appendChild(this.element.cloneNode(true))
      this.element.remove()
      return
    }

    setTimeout(() => {
      this.show()
    }, this.showDelayValue)

    if (this.hasDismissAfterValue) {
      setTimeout(() => {
        this.close()
      }, this.dismissAfterValue)
    }
  }

  close() {
    this.hide()

    setTimeout(() => {
      this.element.remove()
    }, this.removeDelayValue)
  }

  remove() {
    this.element.remove()
  }

  closeWithDelay() {
    setTimeout(() => {
      this.close()
    }, 300)
  }

  show() {
    this.element.firstElementChild.classList.add(...this.showClasses)
    this.element.firstElementChild.classList.remove(...this.hideClasses)
  }

  hide() {
    this.element.firstElementChild.classList.add(...this.hideClasses)
    this.element.firstElementChild.classList.remove(...this.showClasses)
  }
}
