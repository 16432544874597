import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.scrollDown()
  }

  scrollDown() {
    const el = this.element.closest('[id*=chat_messages_service_request_]')
    if (el !== null) {
      el.classList.remove("hidden")

      const chatElement = this.element.closest('[id*=chat_service_request_]')
      const scrollDisabled = chatElement.dataset.newMessagesScrollIntoView && chatElement.dataset.newMessagesScrollIntoView === 'false'
      if (!scrollDisabled) {
        this.element.scrollIntoView()
      } else {
        el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' });
      }
    }
  }
}
