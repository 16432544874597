import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit(ev) {
    if (confirm(ev.target.dataset.confirm)) {
      this.element.requestSubmit()
    } else {
      ev.preventDefault()
      ev.stopPropagation()
    }
  }
}
