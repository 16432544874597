window.selectAgentServiceRequest = function(serviceRequestId) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  fetch(`/agent/service_requests/${serviceRequestId}/select`,
  { method: 'PATCH', headers: { 'X-CSRF-Token': csrfToken } },
  ).then(response => {
    Turbo.visit(window.location.href, { action: 'replace' })
  })
}

window.deselectAgentServiceRequest = function() {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  fetch('/agent/service_requests/deselect',
  { method: 'PATCH', headers: { 'X-CSRF-Token': csrfToken } },
  ).then(response => {  
    Turbo.visit(window.location.href, { action: 'replace' })
  })
}
