function isNewRequestSignalEnabled() {
  return document.getElementById('new-request-signal-switch')?.checked === true
}

function setupNewRequestPeriodicalChecker() {
  window.latestLocalServiceRequestTimestamp = Number(document.body.dataset.latestServiceRequestTimestamp)
  console.debug("periodicalCheck: ", latestLocalServiceRequestTimestamp, window.newRequestPeriodicalChecker)

  if (window.newRequestPeriodicalChecker !== undefined) return;

  window.newRequestPeriodicalChecker = setInterval(() => {
    console.debug("fetch latest_service_request_timestamp")
    fetch('/agent/service_requests/latest_service_request_timestamp')
      .then(response => response.json())
      .then(data => {
        const latestRemoteServiceRequestTimestamp = data.latest_service_request_timestamp
        const timePassedSinceLatest = latestRemoteServiceRequestTimestamp - latestLocalServiceRequestTimestamp

        // console.debug("LOCAL ", window.latestLocalServiceRequestTimestamp)
        // console.debug("SERVER", latestRemoteServiceRequestTimestamp)          

        if (timePassedSinceLatest > 0) {
          window.latestLocalServiceRequestTimestamp = latestRemoteServiceRequestTimestamp
          const noCallIsInProgress = document.querySelector("[id*='call_service_request']") === null &&
                                      document.querySelector("[data-controller='call']") === null
          const agentIsAtTheMainPage = document.querySelector("[data-controller='online-agents']") !== null

          console.debug("NEW REQUEST noCallIsInProgress ", noCallIsInProgress,
            " agentIsAtTheMainPage ", agentIsAtTheMainPage)

          if (isNewRequestSignalEnabled() && noCallIsInProgress && agentIsAtTheMainPage) {
            console.debug("PLAY NEW REQUEST SIGNAL")
            new Audio(document.body.dataset.signalUrl).play()
          }
        }
      })
      .catch(error => console.error('Error: ', error))
  }, 5000)
}

function requestNotificationPermission() {
  console.debug('requestNotificationPermission')

  if ("Notification" in window) {
    console.debug("Notification permission: ", Notification.permission)
    
    if (Notification.permission === "granted") {
      setupNewRequestPeriodicalChecker()
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          setupNewRequestPeriodicalChecker()
        }
      })
    }
  }
}

function showPermissionPopup()  {
  document.getElementById('sound-permission-popup').classList.remove('hidden')
}

function setupNewRequestPeriodicalCheckerIfEnabled() {
  if (isNewRequestSignalEnabled()) {
    requestNotificationPermission()
  } else {
    clearInterval(window.newRequestPeriodicalChecker)
    window.newRequestPeriodicalChecker = undefined
  }
}

async function checkAutoplay() {
  const audio = new Audio(document.body.dataset.silenceUrl);
  try {
    await audio.play();
    console.debug('Autoplay is allowed');
    window.autoplayAllowed = true
  } catch (err) {
    window.autoplayAllowed = false
    console.debug('Autoplay is not allowed', err);
  }
}

window.addEventListener('DOMContentLoaded', (e) => {
  checkAutoplay()
  console.debug("DOMContentLoaded new_request_signal")
  
  const newRequestSignalSwitch = document.getElementById('new-request-signal-switch')
  setupNewRequestPeriodicalCheckerIfEnabled()

  newRequestSignalSwitch?.addEventListener('change', (e) => {
    sessionStorage.setItem('newRequestSignalEnabled', newRequestSignalSwitch.checked)

    if (isNewRequestSignalEnabled()) {
      if (checkAutoplay() && !window.autoplayAllowed) showPermissionPopup()
    }

    setupNewRequestPeriodicalCheckerIfEnabled()
  })
})
